import React, {useState} from "react";


export default (props) => {

  const [open, setOpen] = useState(false);

  const accordianOpen = (e) => {
    e.preventDefault() // Operate the drop down

    let x = document.querySelectorAll('.accordian'); var i;
    for (i = 0; i < x.length; i++) {  x[i].classList.add('dn');}

    let y = document.querySelectorAll('.question'); var i;
    for (i = 0; i < y.length; i++) {  y[i].classList.remove('b');}

    var target = e.target;
    var accord = target.closest(".accordParent");
    var accordian =  accord.querySelector(".accordian");
    if ( accordian.classList.contains('dn')) {
      accordian.classList.remove('dn');
    } 
    var question =  accord.querySelector(".question");
    if ( question.classList.contains('question')) {
      question.classList.add('b');
    } 
  }


   return (
    <div onClick={accordianOpen} className="accordParent w-100" >
        <div className={'question green-100 ' + props.className}> {props.name} </div>
        <div className="accordian gray-100 animated fadeIn dn "  >
            {props.children}
        </div>
    </div>
    );

}

