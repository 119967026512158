import React, {useState, useEffect } from 'react';
import { useTransition, animated, config } from 'react-spring'
import { useStaticQuery, graphql } from "gatsby"

export default () => {


const data = useStaticQuery(graphql`
query ImgBgDutchQuery {
  allFile {
    edges {
      node {
       name 
       relativePath
       publicURL
      }
    }
  }
}
`)

const img = data.allFile.edges

const slides = [{ id: 0, url: img.filter(x => x.node.name === 'biomass_root_no' ).map( ({ node }) => ( node.publicURL )) , caption: `Vóór de aanvraag van `  },
{ id: 1, url: img.filter(x => x.node.name === 'biomass_root' ).map( ({ node }) => ( node.publicURL )) , caption: `Na de aanvraag van ` }]

  const [index, set] = useState(0)
  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  })
  useEffect(() => {
    setInterval(() => {
      set(state => (state + 1) % 2)}, 4000);
    return clearInterval();
  }, [])

  return transitions.map(({ item, props, key }) => (
 
    <div key={key} className="cf relative w-100 bg-white ">
    <animated.div  className="absolute w-100  pointer willchangeOpacity  h300 cover bg-center" style={{ ...props, backgroundImage: `url(${item.url})` }} >
      <div className="f4 fw3 pt1 pb3 bg-white w-100">{item.caption} <b>BioMass Sugar <sup>®</sup></b> </div>
    </animated.div>
    </div>
    
  ))
}