import React from 'react'
import { useStaticQuery, graphql } from "gatsby"



export default (props) => {

    const data = useStaticQuery(graphql`
    query ImgBgQuery {
      allFile {
        edges {
          node {
           name 
           relativePath
           publicURL
          }
        }
      }
    }
  `)

  const img = data.allFile.edges

    return (
           <>
            { img ? img.filter(x => x.node.name === props.name ).map( ({ node }) => ( <div key={node.name} className={props.className} style={{backgroundImage: `url(${node.publicURL})` }}>{props.children}</div> )) : 'no images yet'}
           </>
    )
 
}