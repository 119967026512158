import React from "react";


export default (props) => {

  const openEmail = (e) => {
    e.preventDefault() // Operate the drop down
    var target = e.target;
    var emailname = target.getAttributeNode("data-name").value;
    var domain = target.getAttributeNode("data-domain").value;
    window.location = "mailto:" + emailname + '@' + domain;
}

   return (
    <div >
        <div onClick={openEmail} data-name={props.name} data-domain={props.domain} className='f7 fw5 green-100 pointer'> Send email to – {props.fullname} </div>
    </div>
    );

}

