import React from "react"
import Nav from "./nav"
import Footer from "./footer"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"
import Accordian from "./accordian"
import BgImageholder from "./backgroundImage"
import Imageholder from "./image"
import Email from "./email"
import Rootsy from "./rootsyDutch"
import RootsySpanish from "./rootsySpanish"

const BiomassSugarName = () => ( <b>BioMass Sugar <sup>®</sup></b> )
const shortcodes = { Link, Accordian, BgImageholder, Email, BiomassSugarName, Imageholder, Rootsy, RootsySpanish  }

const Layout = ({ children }) => {

 
  return (
    <MDXProvider components={shortcodes}>
      <div className="w-100">
        <Nav/>
        <main>{children}</main>
        <Footer />
      </div>
    </MDXProvider>
  )
}

export default Layout
