import React from "react"
import logo from "./../graphics/biomasslogo.svg"
import caae from "./../graphics/caae.svg"
import illovo from "./../graphics/illovo_sugar_sa.svg"


export default () => {
  return (
    <>
      <div className="w-100 bg-white pt2 bg-gray-00">

        <div className="mw9 center w-100 ph4">
          <div className="flex flex-column flex-row-m ">

            <div className="w-100 w-50-m f7 green pv3">
                <div className="br05 db ">
                <img className="h2-5" src={logo} alt="..." />
                </div>
                <div className="ls2  f6  green-100">
                PRODUCED FROM SUGAR CANE
                </div>
            </div>

           

          </div>
        </div>

      </div>

      <div className="w-100 bg-green-100 min-vh-40 pv5">
        <div className="mw9 center w-100 ph4">
          <div className="flex flex-column flex-row-m">
           
{/*
            <div className="f7 white ls1">
              <img className="h4 mb3" src={illovo} alt="..." />
              <div className="fw3"> Registered and formulated by: </div>
              <div className="fw5"> Illovo Sugar (South Africa) (Pty) Ltd </div>
              <div className="fw3"> Co Reg No. 1906/000622/07 </div>
              <div className="fw3">PO Box 194, Durban, South Africa, 4000 </div>
              <div className="fw3"> Tel: +27 (031) 31 508 4588 </div>
            </div>
*/}           
          </div>
        </div>
      </div>
    </>
  )
}
