import React, { useState } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import logo from "./../graphics/biomasslogo.svg"
import bgworld from "./../graphics/worldmap_grey.svg"



export default () => {
  
 const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuFunction = (e) => {
    e.preventDefault() // Operate the drop down
    !isMenuOpen ? setIsMenuOpen(true) : setIsMenuOpen(false)
  }

const location = useLocation();
console.log( location.pathname )
console.log ( location.pathname.includes('english') ? 'english' : 'dutch'  )


  return (
    <div className="cf bg-gray-00 ls05">

            <div className="mw9 center pv3 ph4 pv4-s flex justify-center justify-start-s items-center bg-top-right"  style={{backgroundImage: `url(${bgworld})` }}>
                <Link className="dib mr3 flex items-center tracked" to="/" >
                  <img className="h2-5 h3-s mr3" src={logo} alt='biomass sugar logo'/>
                </Link>
                <div className="f6 fw4 ls25 green-100 lh-1 dn db-s">PRODUCED FROM SUGAR CANE</div>
            </div>


{ location.pathname.includes('dutch')   ? 
            <div className="w-100 bg-green-100">
                <div className="mw9 center ph0 ph3-l">
                  <div className={ !isMenuOpen ? "topnav " : "topnav responsive animated fadeIn "}  id="myTopnav">
                    <div className="dropdown">
                      <button className="dropbtn ls05">Home</button>
                      <div className="dropdown-content animated fadeIn faster">
                        <Link to="/">  English  </Link>
                        <Link to="/dutch">    Dutch    </Link>
                        <Link to="/spanish">  Spanish  </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <button className="dropbtn ls05">Product Informatie</button>
                      <div className="dropdown-content animated fadeIn faster">
                        <Link to="/dutch/unique_characteristics_dutch">    Unieke Eigenschappen  </Link>
                        <Link to="/dutch/product_description_dutch">       Product Omschrijving    </Link>
                        <Link to="/dutch/product_label_dutch">             Product Label  </Link>
                        <a href="./../Biomass_Sugar_Material_Saftey_Dutch.pdf">  Material Safety Data Sheet PDF  </a>
                      </div>
                    </div>
                    <div className="dropdown">
                      <button className="dropbtn ls05">Product Applicatie</button>
                      <div className="dropdown-content animated fadeIn faster">
                        <Link to="/dutch/dosage_rates_dutch">         Doseringshoeveelheden  </Link>
                        <Link to="/dutch/hand_spray_dutch">           Handsproeier    </Link>
                        <Link to="/dutch/irrigation_dutch">           Irrigatie </Link>
                      </div>
                    </div>
                    <Link to="/dutch/faq_dutch">Vaakgestelde vragen</Link>
                    <Link to="/english/contacts">Contacten</Link>
                   <div className="flex justify-between items-center">
                    <div className="f6 fw4 ls15 green-25 lh-1 dib dn-s pv2 pl3">PRODUCED FROM SUGAR CANE</div>
                    <a href="#" className="icon pointer" onClick={menuFunction}>&#9776;</a>
                    </div>
                  </div>
                </div>
            </div>
  : location.pathname.includes('spanish')  ?
            <div className="w-100 bg-green-100">
            <div className="mw9 center ph0 ph3-l">
              <div className={ !isMenuOpen ? "topnav " : "topnav responsive animated fadeIn "}  id="myTopnav">
                <div className="dropdown">
                  <button className="dropbtn ls05">Home</button>
                  <div className="dropdown-content animated fadeIn faster">
                    <Link to="/">  English  </Link>
                    <Link to="/dutch">    Dutch    </Link>
                    <Link to="/spanish">  Spanish  </Link>
                  </div>
                </div>
                <div className="dropdown">
                  <button className="dropbtn ls05">Informaci&#0243;n del producto </button>
                  <div className="dropdown-content animated fadeIn faster">
                    <Link to="/spanish/unique_characteristics_spanish">      Caracter&#237;sticas &#250;nicas  </Link>
                    <Link to="/spanish/product_description_spanish">         Descripci&#243;n del producto    </Link>
                    <Link to="/spanish/product_label_spanish">               Etiquetado del producto  </Link>
                    <a href="./../Biomass_Sugar_Ficha_de_seguridad.pdf">     Ficha de seguridad  </a>
                  </div>
                </div>
                <div className="dropdown">
                  <button className="dropbtn ls05">Uso del Producto</button>
                  <div className="dropdown-content animated fadeIn faster">
                    <Link to="/spanish/dosage_rates_spanish">       Porcentajes de dosis  </Link>
                    <Link to="/spanish/hand_spray_spanish">         Pulverizaci&#243;n Manual    </Link>
                    <Link to="/spanish/irrigation_spanish">         Regadi </Link>
                  </div>
                </div>
                <Link to="/spanish/faq_spanish">Preguntas Más Frecuentes</Link>
                <Link to="/english/contacts">Contactos</Link>
              <div className="flex justify-between items-center">
                <div className="f6 fw4 ls15 green-25 lh-1 dib dn-s pv2 pl3">PRODUCED FROM SUGAR CANE</div>
                <a href="#" className="icon pointer" onClick={menuFunction}>&#9776;</a>
                </div>
              </div>
            </div>
            </div>

            :

            <div className="w-100 bg-green-100">
            <div className="mw9 center ph0 ph3-l">
              <div className={ !isMenuOpen ? "topnav " : "topnav responsive animated fadeIn "}  id="myTopnav">
                <div className="dropdown">
                  <button className="dropbtn ls05">Home</button>
                  <div className="dropdown-content animated fadeIn faster">
                    <Link to="/">  English  </Link>
                    <Link to="/dutch">    Dutch    </Link>
                    <Link to="/spanish">  Spanish  </Link>
                  </div>
                </div>
                <div className="dropdown">
                  <button className="dropbtn ls05">Product Information</button>
                  <div className="dropdown-content animated fadeIn faster">
                    <Link to="/english/unique_characteristics">      Unique Characteristics  </Link>
                    <Link to="/english/product_description">         Product Description    </Link>
                    <Link to="/english/product_label">               Product Label  </Link>
                    <a href="./../Biomass_Sugar_Material_Saftey_Data_Sheet.pdf">  Material Safety Data Sheet PDF  </a>
                  </div>
                </div>
                <div className="dropdown">
                  <button className="dropbtn ls05">Product Application</button>
                  <div className="dropdown-content animated fadeIn faster">
                    <Link to="/english/dosage_rates">       Dosage Rates  </Link>
                    <Link to="/english/hand_spray">         Hand Spray    </Link>
                    <Link to="/english/irrigation"> Irrigation Systems </Link>
                  </div>
                </div>
                <Link to="/english/faq">Frequently Asked Question</Link>
                <Link to="/english/contacts">Contacts</Link>
              <div className="flex justify-between items-center">
                <div className="f6 fw4 ls15 green-25 lh-1 dib dn-s pv2 pl3">PRODUCED FROM SUGAR CANE</div>
                <a href="#"   className="icon pointer" onClick={menuFunction}>&#9776;</a>
                </div>
              </div>
            </div>
            </div>
}
          
       
    

      </div>
  )
}